import { useState, useEffect } from 'react';
import { logError } from '@websites/utils';
import { IProduct, IProductVariant } from '../model/client.model';
import { getShopifyClient } from './shopifyClient';

/**
 * Custom hook that async fetches the live availability status for an entire product line (i.e. at least one variant available)
 */
export const useShopifyProductIsAvailableForSale: (
  product?: IProduct
) => boolean | null = (product) => {
  const [isAvailableForSale, setIsAvailableForSale] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (product) {
      const shopify = getShopifyClient();
      shopify.product
        .fetch(product.shopifyId)
        .then((prod) => {
          setIsAvailableForSale(prod.availableForSale);
        })
        .catch((err) => logError(
          `Error while attempting to check quantity in stock for product [${product.title}]: `,
          err,
        ));
    }
  }, [product]);

  return isAvailableForSale;
};

/**
 * Custom hook that async fetches the live availability status for a particular variant of a product line
 */
export const useShopifyProductVariantIsAvailableForSale: (
  product?: IProduct,
  productVariant?: IProductVariant
) => boolean | null = (product, productVariant) => {
  const [isAvailableForSale, setIsAvailableForSale] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (product && productVariant) {
      const shopify = getShopifyClient();
      shopify.product
        .fetch(product.shopifyId)
        .then((prod: IProduct) => {
          const liveVariants: any[] = prod.variants.filter(
            // note sdk product variants shopifyId is actually in the 'id' property - inconsistent with the graphql shopify plugin
            (v) => v.id === productVariant.shopifyId,
          );
          const liveVariant = liveVariants[0];
          setIsAvailableForSale(liveVariant.available); // note different property name between shopify graphql api and sdk api
        })
        .catch((err) => logError(
          `Error while attempting to check quantity in stock for product [title: ${product.title}] variant [title: ${productVariant.title}, shopifyId: ${productVariant.shopifyId}]:`,
          err,
        ));
    }
  }, [product, productVariant]);

  return isAvailableForSale;
};
