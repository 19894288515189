import React from 'react';
import Image from 'gatsby-image';
import { RichTextHTML, ThemeContext } from '@websites/components';
import { IGreeting } from '@websites/model/client.model';

const Greeting: React.FC<{
  greeting: IGreeting;
}> = ({ greeting, className }) => {
  const { bg, text } = React.useContext(ThemeContext);
  const {
    title, body, gatsbyImageImage, signatureImage,
  } = greeting;

  return (
    <div
      className={`${bg('highlight-dark')} ${text('highlight-light')} ${className} py-8 md:py-12`}
    >
      <div className="flex flex-col lg:flex-row items-center max-width-wrapper mx-auto px-4">
        {gatsbyImageImage && (
          <div className="flex items-center">
            <Image
              fluid={gatsbyImageImage?.childImageSharp?.fluid}
              className="border-white border-2 sm:border-4 object-cover w-24 h-24 min-w-24 md:h-48 md:w-48 md:min-w-48 lg:h-64 lg:w-64 lg:min-w-64 xl:h-72 xl:w-72 xl:min-w-72 rounded-full shadow-lg hover:shadow-xl bg-white p-1"
              backgroundColor="whitesmoke"
              alt="Profile photo to go with the greeting message"
            />
          </div>
        )}
        <div className="flex flex-col mt-4 lg:ml-8 items-center lg:items-start space-y-4 sm:px-4 md:px-8">
          <h2 className="font-bold uppercase tracking-widest text-lg md:text-2xl lg:text-3xl text-center font-title mb-2">
            {title}
          </h2>
          <RichTextHTML className="text-center lg:text-left text-base space-y-3">
            {body}
          </RichTextHTML>
          {signatureImage && (
            <img
              src={signatureImage}
              alt="signature"
              className="h-12"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Greeting;
