import * as R from 'ramda';

export const shopifyCatalogToFlattenedProducts = (catalog) => R.compose(
  R.map((o) => {
    const prod: any = {
      name: R.prop('title')(o),
    };
    if (o?.images?.length) {
      prod.image = o.images[0].originalSrc;
    }
    return prod;
  }),
  R.flatten,
  R.map(R.prop('products')),
  R.defaultTo([]),
)(catalog);
