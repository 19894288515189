import { IProduct } from '../model/client.model';

/**
 * A product is pre-orderable if it has a 'pre-order' tag on its Shopify entry.
 */
export const isPreOrderable = (product: IProduct) => {
  const preOrderTag = product.tags?.find(
    (t) => t.toLowerCase() === 'preorder' || t.toLowerCase() === 'pre-order',
  );
  return preOrderTag ? preOrderTag.length > 0 : false;
};
