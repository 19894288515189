import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Layout, SectionHeroBanner } from '@websites/components';

import { productsToJSONLDOfferCatalog } from '@websites/utils';
import { IStore } from '../model/client.model';
import ProductCatalog from '../components/ProductCatalog/ProductCatalog';
import Greeting from '../components/Greeting';

import { YellowBusinessContext } from '../contexts/YellowBusinessProvider';
import { CartContext } from '../contexts/CartProvider';

import { shopifyCatalogToFlattenedProducts } from '../utils/shopifyCatalogToJsonLDProducts';

// @ts-ignore
import shopComingSoonArtwork from '../assets/shop-coming-soon-artwork.svg';

import * as styles from './index.module.css';

const query = graphql`
  query MyQuery {
    shopifyProductCollections: allShopifyCollection {
      nodes {
        handle
        title
        shopifyId
        products {
          availableForSale
          createdAt
          handle
          shopifyId
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          publishedAt
          title
          description
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 5120) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          tags
          variants {
            title
            id
            shopifyId
            selectedOptions {
              name
              value
            }
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 5120) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
            }
          }
        }
      }
    }
  }
`;

const StoreComingSoonPlaceholderNotice = () => (
  <div id="catalog" className="bg-gray-100 py-16 lg:py-32 flex flex-col items-center space-y-2 md:space-y-8">
    <div className="text-lg md:text-2xl text-center" style={{ color: '#A4AFBE' }}> Online Store coming soon! </div>
    <img src={shopComingSoonArtwork} className="max-w-64 sm:max-w-lg md:max-w-xl lg:max-w-3xl" alt="Shop coming soon" />
  </div>
);

const Index = () => {
  const shopifyQuery = useStaticQuery(query);

  const { yellowBusiness, sections } = useContext(YellowBusinessContext);
  const cart = useContext(CartContext);

  if (!yellowBusiness) {
    return <> </>;
  }

  const store: IStore = {
    name: yellowBusiness?.name || '',
    catalogue: shopifyQuery.shopifyProductCollections?.nodes,
  };

  /**
 * Turn shopify catalog into a flattened list of JSON-LD products
 */
  const hasOfferCatalog = productsToJSONLDOfferCatalog(shopifyCatalogToFlattenedProducts(store.catalogue));

  yellowBusiness.website.jsonLD = { ...yellowBusiness.website.jsonLD, hasOfferCatalog };

  const customerShopifyStoreIsNotYetReady = `${process.env.GATSBY_SHOPIFY_STORE_NAME}` === 'yellow-placeholder-store-never-delete';

  const storeHasAtLeastOneProduct = () => {
    if (store.catalogue?.length) {
      const categoryWithAtLeastOneProduct = store.catalogue.find((cat) => cat.products && cat.products.length > 0);
      return !!categoryWithAtLeastOneProduct;
    }
    return false;
  };

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout isEcommerce headerTransparent yellowBusiness={yellowBusiness} sections={sections} cart={cart}>
          <SectionHeroBanner yellowBusiness={yellowBusiness} isEcommerce />
          {yellowBusiness.website.greeting && <Greeting className={styles.greeting} greeting={yellowBusiness.website.greeting} /> }
          {!customerShopifyStoreIsNotYetReady && storeHasAtLeastOneProduct() && <ProductCatalog categories={store.catalogue} /> }
          {(customerShopifyStoreIsNotYetReady || !storeHasAtLeastOneProduct()) && <StoreComingSoonPlaceholderNotice />}
        </Layout>
      )}
    </>
  );
};

export default Index;
