import React, { useState, useEffect } from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProduct } from '../model/client.model';
import { getPercentDiscountRange } from '../utils/discountUtils';

const DiscountBadge: React.FC<{ product: IProduct }> = ({ product }) => {
  const [isDiscounted, setIsDiscounted] = useState(false);

  useEffect(() => {
    const discountRange = getPercentDiscountRange(product.variants);
    setIsDiscounted(!!discountRange);
  }, [product]);

  return (
    <>
      {isDiscounted && (
        <span className="bg-red-600 text-red-100 uppercase tracking-wider text-xs py-1 px-2 font-sans font-bold shadow space-x-1">
          <FontAwesomeIcon icon={faTag} />
          <span>sale</span>
        </span>
      )}
    </>
  );
};

export default DiscountBadge;
