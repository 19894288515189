/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IProductVariant } from '../model/client.model';

export const getPercentDiscountRange = (variants: IProductVariant[]) => {
  if (!variants) {
    return null;
  }

  const sortedDiscounts = variants
    .filter((vr) => !!vr.compareAtPriceV2?.amount)
    .map(
      (vr) => (((Number.parseFloat(vr.compareAtPriceV2!.amount)
          - Number.parseFloat(vr.priceV2.amount))
          * 1.0)
          / Number.parseFloat(vr.compareAtPriceV2!.amount))
        * 100,
    )
    .sort((disc1, disc2) => disc1 - disc2);

  if (sortedDiscounts.length) {
    const min = sortedDiscounts[0];

    if (sortedDiscounts.length > 1) {
      const max = sortedDiscounts[sortedDiscounts.length - 1];
      return {
        minDiscount: Math.round(min),
        maxDiscount: Math.round(max),
      };
    }
    return {
      minDiscount: Math.round(min),
    };
  }
  return null;
};
