import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as R from 'ramda';
import { fireClickAnalyticsEvent } from '@websites/utils';
import { FluidImage } from '@websites/components';
import { IProduct } from '../../model/client.model';
import { useShopifyProductIsAvailableForSale } from '../../utils/useShopifyInStockStatus';
import DiscountBadge from '../DiscountBadge';
import { formatCurrency } from '../../utils/formatCurrency';
import { getPercentDiscountRange } from '../../utils/discountUtils';
import { isPreOrderable } from '../../utils/isPreOrderable';
import placeholder from '../../assets/placeholder.svg';

import * as styles from './ProductListing.module.css';

const getAvailableColors = R.compose(
  R.pluck('value'),
  R.uniq,
  R.filter(R.propEq('name', 'Color')),
  R.flatten,
  R.map(R.prop('selectedOptions')),
  R.prop('variants'),
);

const ProductListing: React.FC<{
  product: IProduct;
}> = ({ product }) => {
  const isInStock = useShopifyProductIsAvailableForSale(product);
  const availableColors = getAvailableColors(product);

  const productIsDiscounted = !!getPercentDiscountRange(product.variants);
  const productHasPriceRange = product.priceRange.maxVariantPrice
    && product.priceRange.maxVariantPrice.amount
      !== product.priceRange.minVariantPrice.amount;

  const isPreOrderableProduct = isPreOrderable(product);

  return (
    <Link to={`/${product.handle}`} onClick={() => fireClickAnalyticsEvent('link_click', `Product: ${product?.title}`)}>
      <div className={`w-full h-full flex flex-col space-y-8 p-4 lg:p-8 ${styles.item}`} id={product?.shopifyId}>
        <div className={`overflow-hidden w-full rounded-sm ${styles.image}`}>
          {product.images?.[0] ? (
            <>
              <div>
                <FluidImage
                  fluid={product.images[0]?.localFile?.childImageSharp?.fluid}
                  src={product.images[0]?.localFile?.originalSrc}
                  fit="contain"
                  alt={`Image for product named ${product?.title}`}
                  aspectRatio={1 / 1}
                />
              </div>
              {product.images?.[1] && (
                <div className={`${styles.hoverImage}`}>
                  <FluidImage
                    fluid={product.images[1]?.localFile?.childImageSharp?.fluid}
                    src={product.images[1]?.localFile?.originalSrc}
                    fit="contain"
                    alt={`Image for product named ${product?.title}`}
                    aspectRatio={1 / 1}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="h-full w-full flex justify-center center">
              <img
                src={placeholder}
                alt="Placeholder - not product"
                className="w-1/2"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start space-y-2 flex-1">
          {isPreOrderableProduct && (
            <span className="uppercase tracking-wider text-xs py-1 px-2 font-sans bg-gray-200 text-gray-800">
              pre-order
            </span>
          )}
          <h3 className="text-lg md:text-xl lg:text-2xl font-title flex space-x-2 items-center">
            {product?.title}
          </h3>
          <div className="flex justify-between w-full items-baseline text-base lg:text-xl">
            <div className="flex items-center space-x-1 font-semibold">
              <div>
                <span className="text-gray-700">
                  {product?.variants[0]?.compareAtPriceV2?.amount && (
                    <del className="mr-2">
                      {formatCurrency(product?.variants[0]?.compareAtPriceV2?.amount)}
                    </del>
                  )}
                </span>
                <span className={`${productIsDiscounted ? 'text-red-600 font-bold' : ''}`}>
                  {formatCurrency(product?.priceRange?.minVariantPrice?.amount)}
                </span>
                {productHasPriceRange && (
                  <span>
                    &nbsp;
                    <span>
                      &mdash;
                    </span>
                    &nbsp;
                    {formatCurrency(product?.priceRange?.maxVariantPrice?.amount)}
                  </span>
                )}
              </div>
              <DiscountBadge product={product} />
            </div>
            <div>
              <div className="uppercase text-xs tracking-wide flex items-center space-x-2">
                {isInStock === false && !isPreOrderableProduct && (
                  <span className="text-gray-500 space-x-1 flex items-center">
                    <span>out of stock</span>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                )}
                {isInStock === true && availableColors?.length > 1 && (
                  <span className="text-gray-600 text-right pl-2">
                    {availableColors?.join(' / ')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductListing;
