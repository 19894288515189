import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';

import { ThemeContext } from '@websites/components';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import ProductGallery from '../ProductGallery';
import { IProductCategory } from '../../model/client.model';
import { NavigationContext } from '../../contexts/NavigationProvider';

import * as styles from './ProductCatalog.module.css';

/**
 * Arranges a menu of product categories and their nested product galleries
 */
const ProductCatalog: React.FC<{
  categories?: IProductCategory[];
}> = ({ categories }) => {
  const { bg, text, border } = useContext(ThemeContext);
  const { setLastSelectedCategoryHandle } = useContext(NavigationContext);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]);

  const categoryIdMap = useMemo(() => {
    const map = {};
    categories?.forEach((c) => { (map[c.shopifyId] = c); });
    return map;
  }, [categories]);

  const location = useLocation();

  const selectCategory = (cat: IProductCategory) => {
    setLastSelectedCategoryHandle(cat.handle);
    setSelectedCategory(cat);
  };

  useEffect(() => {
    if (location && categories) {
      const selectedCat = categories.find((cat) => location.hash === `#catalog-${cat.handle}`);
      if (selectedCat) {
        setLastSelectedCategoryHandle(selectedCat.handle);
        setSelectedCategory(selectedCat);
      } else {
        setSelectedCategory(categories[0]);
      }
    }
  }, [location, categories]);

  const [categoryFilterTopPos, setCategoryFilterTopPos] = useState(0);
  React.useLayoutEffect(() => {
    function setStickyTop() {
      const headerEl = document.querySelector('#header');
      setCategoryFilterTopPos(headerEl?.clientHeight);
    }

    setStickyTop();
    window.addEventListener('scroll', setStickyTop);

    return () => { window.removeEventListener('scroll', setStickyTop); };
  }, []);

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (location.hash) {
      const routeHash = window.location.hash.replace('#', '');
      const scrollToSection = document.querySelector(`#custom_${routeHash}`);

      if (routeHash === 'catalog' && scrollToSection) {
        const sectionOffsetTop = scrollToSection.offsetTop;

        const headerEl = document.querySelector('#header');
        const headerHeight = headerEl?.clientHeight || 70;

        // weird bug where on first load, I had to double the header height because of the position sticky (?) Not too sure, but it fixed the issue
        const headerOffset = headerHeight * ((firstLoad) ? 2 : 1);

        window.scroll({
          top: sectionOffsetTop - headerOffset,
          behavior: 'smooth',
        });
      }
    }

    setFirstLoad(false);
  }, [location]);

  return (
    <>
      <div
        id="custom_catalog"
        style={{
          zIndex: '49',
          margin: '0px calc(50% - 50vw)',
          width: '100vw',
          top: `${categoryFilterTopPos}px`,
        }}
        className={`p-6 flex items-center justify-center border-b ${border('highlight-dark')} border-opacity-50 w-full mx-auto sticky top-0 bg-white`}
      >
        <div className="flex flex-col md:flex-row w-full justify-center items-center">
          {categories && categories.length > 1 && (
            <>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="categoriesSelect" className="mb-2 md:mb-0 md:mr-3"> Select Product Category: </label>
              <select
                id="categoriesSelect"
                onChange={(e) => {
                  const selectedCat: IProductCategory = categoryIdMap[e.target.value];
                  selectCategory(selectedCat);
                  navigate(`#catalog-${selectedCat.handle}`);
                }}
                className={`text-xs w-full sm:w-auto uppercase tracking-widest ${text('highlight-dark')} ${border('highlight-dark')}`}
                value={selectedCategory ? selectedCategory.shopifyId : categories?.[0].shopifyId}
              >
                {categories?.map((cat) => (
                  <option key={cat.shopifyId} value={cat.shopifyId}>
                    {cat.title}
                  </option>
                ))}
              </select>
            </>
          )}
          {categories?.length === 1 && categories.map((cat) => (
            <span className={selectedCategory === cat ? 'font-bold' : ''} key={cat.shopifyId}>
              <Link
                className={`uppercase tracking-widest ${text('base-dark')} cursor-pointer hover:${text('highlight-dark')}`}
                onClick={() => selectCategory(cat)}
                to={`#catalog-${cat.handle}`}
              >
                {cat.title}
              </Link>
            </span>
          ))}
        </div>
      </div>
      <div style={{ margin: '0px calc(50% - 50vw)', width: '100vw' }} className={`${bg('base-light')} py-10 ${styles.productGallery}`}>
        <div className="max-width-wrapper mx-auto lg:mt-5">
          <ProductGallery category={selectedCategory} />
        </div>
      </div>
    </>
  );
};

export default ProductCatalog;
