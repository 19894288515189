import React from 'react';
import { IProductCategory } from '../model/client.model';
import ProductListing from './ProductListing/ProductListing';

const ProductGallery: React.FC<{
  category?: IProductCategory;
}> = ({ category }) => (
  <div
    className={`
      grid grid-flow-row 
      grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
      gap-4 md:gap-6 lg:gap-12
    `}
  >
    {category?.products?.map((prod) => (
      <div className="w-full" key={prod.shopifyId}>
        <ProductListing product={prod} />
      </div>
    ))}
  </div>
);

export default ProductGallery;
